import BaseApi from "./BaseApi";
import { version } from '../config'

type type = 'play_movie' | 'down_movie' | 'play_audio' | 'down_audio'

class TongjiApi extends BaseApi {
    token: string = ''
    constructor() {
        super('https://statistics.sanmanuela.net')
    }


    postVideo(type: type, movid: number, urlid: number): Promise<any> {
        const types: any = {
            play_movie: '1',
            down_movie: '2',
            play_audio: '3',
            down_audio: '4',
        }
        type = types[type]
        let query = { app: 'fuyintv', platform: 'pc', version, type, movid, urlid, }
        return this.get(`/v1/tv/video`, query)
    }
}

export default new TongjiApi()
